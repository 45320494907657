<template>
  <!-- Basko has custom PaymentTypeList because v-select was a mandatory request from client -->
  <div
    v-if="paymentTypeList && paymentTypeList.length > 0"
    class="payment-methods mt-5 py-3 rounded-sm"
    :style="{ 'background-color': options.mainBackgroundColor || 'white' }"
  >
    <div
      v-if="!isProfileView"
      :class="
        options.titleStyle
          ? options.titleStyle
          : 'text-h3 secondary--text mb-5 font-weight-bold text--lighten-1'
      "
    >
      {{ $t("paymentTypeList.title") }}
    </div>
    <i18n path="paymentTypeList.description" tag="p" v-if="!isProfileView" />
    <v-alert v-if="isPaymentDisabled" type="error" dense>
      {{ $t("paymentTypeList.disabled") }}
    </v-alert>
    <div v-else>
      <v-select
        class="select-payment-type-checkbox"
        color="primary"
        :label="$t('payment.selectPaymentType')"
        outlined
        v-model="selectedPayment"
        hide-details
        :items="paymentTypeList"
        item-text="name"
        return-object
        @change="setPaymentInfo(selectedPayment.name)"
      >
      </v-select>

      <div
        class="gateway-writer-container mt-6"
        v-if="selectedPayment"
        :key="selectedPayment.paymentTypeId"
      >
        <div class="gateway-title">
          <label class="text-h4" :for="selectedPayment.paymentTypeId">
            <v-img
              v-if="options.showPaymentMethodImage"
              :src="`/paymentmethod/${selectedPayment.paymentTypeId}.svg`"
              contain=""
              eager
              onerror="this.onerror=null;this.src=''"
              height="37"
              max-width="60"
              class="mx-3 payment-method-img"
              alt="Metodo di pagamento"
            />
            {{ selectedPayment.name }}
          </label>
          <!-- CREDIT CARDS -->
          <div
            class="d-flex align-center"
            :class="
              options.cardsPosition ? options.cardsPosition : 'justify-end'
            "
            v-if="
              options.showCardLogos &&
                [10, 12, 22].includes(selectedPayment.paymentTypeId) &&
                !$vuetify.breakpoint.xs
            "
          >
            <v-img
              eager
              src="/credit-card/visa-curved-64px.png"
              alt="Visa"
              height="37"
              max-width="60"
              class="mr-1"
            ></v-img>
            <!-- <v-img
              eager
              src="/credit-card/electron-curved-64px.png"
              alt="Visa Electron"
              height="37"
              max-width="60"
              class="mr-1"
            ></v-img> -->
            <v-img
              eager
              src="/credit-card/mastercard-curved-64px.png"
              alt="Mastercard"
              height="37"
              max-width="60"
              class="mr-1"
            ></v-img>
            <v-img
              eager
              src="/credit-card/maestro-curved-64px.png"
              alt="Maestro"
              height="37"
              max-width="60"
              class="mr-1"
            ></v-img>
            <v-img
              eager
              src="/credit-card/amex-curved-64px.png"
              alt="Visa"
              height="37"
              max-width="60"
              class="mr-1"
            ></v-img>
          </div>
          <!-- PAYPAL -->
          <div
            class="d-flex align-center justify-end"
            no-gutters
            v-if="
              options.showCardLogos &&
                [13].includes(selectedPayment.paymentTypeId) &&
                !$vuetify.breakpoint.xs
            "
          >
            <v-img
              eager
              src="/credit-card/paypal-transparent-64px.png"
              alt="Paypal"
              height="37"
              max-width="90"
            ></v-img>
          </div>
        </div>
        <GatewayWriter
          class="mt-5"
          :payment-type="selectedPayment"
          :order-id="orderId"
          :component-name="selectedPayment.gatewayClient"
          :mode="mode"
          :options="mergedOptions"
          :isProfileView="isProfileView"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GatewayWriter from "~/components/payment/gateways/GatewayWriter.vue";
import PaymentTypeService from "~/service/paymentTypeService";

import merge from "lodash/merge";
import { mapState } from "vuex";
import AnalyticsService from "~/service/analyticsService";

export default {
  name: "PaymentTypeList",
  props: {
    orderId: { type: Number, required: false },
    isPaymentDisabled: { type: Boolean, default: false },
    defaultPaymentType: { type: Number, required: false, default: 0 },
    options: { type: Object, required: false, default: () => ({}) },
    autoSelectPaymentType: { type: Boolean, default: true },
    filteredPaymentTypeIds: { type: Array, required: false },
    modality: { type: String, required: false },
    isProfileView: { type: Boolean, default: false },
    paymentNameToPaymentInfo: { type: Boolean, default: false }
  },
  components: { GatewayWriter },
  data() {
    return {
      selectedPayment: null,
      paymentTypeList: []
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    mode() {
      return this.modality ? this.modality : this.orderId ? "order" : "cart";
    },
    mergedOptions() {
      return merge(
        {
          showRememberCard: true,
          showPaymentMethodImage: false,
          showCardLogos: true,
          showAddNewCardWarning: true,
          confirm: {
            show: !this.isProfileView,
            label: this.$t("paymentTypeList.confirm"),
            color: "primary",
            outline: false,
            icon: null,
            link: false,
            large: true,
            depressed: true
          },
          back: {
            show: !this.isProfileView,
            label: this.$t("common.back"),
            color: "primary",
            outline: true,
            icon: null,
            link: false,
            large: true,
            depressed: true
          }
        },
        this.options
      );
    }
  },
  methods: {
    fetchPaymentTypeList() {
      PaymentTypeService.getPaymentTypeList(this.orderId, true).then(
        paymentTypeList => {
          this.paymentTypeList = this.filteredPaymentTypeIds
            ? paymentTypeList.filter(paymentType =>
                this.filteredPaymentTypeIds.includes(paymentType.paymentTypeId)
              )
            : paymentTypeList;
          if (this.autoSelectPaymentType) {
            const paymentTypeId = this.$store.state.cart.cart.paymentTypeId;
            const paymentTypeIndex = paymentTypeList.find(
              paymentType => paymentType.paymentTypeId === paymentTypeId
            );
            this.selectedPayment =
              paymentTypeIndex !== -1
                ? paymentTypeIndex
                : this.defaultPaymentType;
            console.log("this.selectedPayment", this.selectedPayment);
          }
        }
      );
    },
    setPaymentInfo(name) {
      AnalyticsService.checkoutOptions(2, "payment_method", name);
      AnalyticsService.addPaymentInfo(
        this.cart,
        this.paymentNameToPaymentInfo ? name : undefined
      );
    }
  },
  created() {
    this.fetchPaymentTypeList();
  }
};
</script>
