var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.paymentTypeList && _vm.paymentTypeList.length > 0)?_c('div',{staticClass:"payment-methods mt-5 py-3 rounded-sm",style:({ 'background-color': _vm.options.mainBackgroundColor || 'white' })},[(!_vm.isProfileView)?_c('div',{class:_vm.options.titleStyle
        ? _vm.options.titleStyle
        : 'text-h3 secondary--text mb-5 font-weight-bold text--lighten-1'},[_vm._v(" "+_vm._s(_vm.$t("paymentTypeList.title"))+" ")]):_vm._e(),(!_vm.isProfileView)?_c('i18n',{attrs:{"path":"paymentTypeList.description","tag":"p"}}):_vm._e(),(_vm.isPaymentDisabled)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.$t("paymentTypeList.disabled"))+" ")]):_c('div',[_c('v-select',{staticClass:"select-payment-type-checkbox",attrs:{"color":"primary","label":_vm.$t('payment.selectPaymentType'),"outlined":"","hide-details":"","items":_vm.paymentTypeList,"item-text":"name","return-object":""},on:{"change":function($event){return _vm.setPaymentInfo(_vm.selectedPayment.name)}},model:{value:(_vm.selectedPayment),callback:function ($$v) {_vm.selectedPayment=$$v},expression:"selectedPayment"}}),(_vm.selectedPayment)?_c('div',{key:_vm.selectedPayment.paymentTypeId,staticClass:"gateway-writer-container mt-6"},[_c('div',{staticClass:"gateway-title"},[_c('label',{staticClass:"text-h4",attrs:{"for":_vm.selectedPayment.paymentTypeId}},[(_vm.options.showPaymentMethodImage)?_c('v-img',{staticClass:"mx-3 payment-method-img",attrs:{"src":`/paymentmethod/${_vm.selectedPayment.paymentTypeId}.svg`,"contain":"","eager":"","onerror":"this.onerror=null;this.src=''","height":"37","max-width":"60","alt":"Metodo di pagamento"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.selectedPayment.name)+" ")],1),(
            _vm.options.showCardLogos &&
              [10, 12, 22].includes(_vm.selectedPayment.paymentTypeId) &&
              !_vm.$vuetify.breakpoint.xs
          )?_c('div',{staticClass:"d-flex align-center",class:_vm.options.cardsPosition ? _vm.options.cardsPosition : 'justify-end'},[_c('v-img',{staticClass:"mr-1",attrs:{"eager":"","src":"/credit-card/visa-curved-64px.png","alt":"Visa","height":"37","max-width":"60"}}),_c('v-img',{staticClass:"mr-1",attrs:{"eager":"","src":"/credit-card/mastercard-curved-64px.png","alt":"Mastercard","height":"37","max-width":"60"}}),_c('v-img',{staticClass:"mr-1",attrs:{"eager":"","src":"/credit-card/maestro-curved-64px.png","alt":"Maestro","height":"37","max-width":"60"}}),_c('v-img',{staticClass:"mr-1",attrs:{"eager":"","src":"/credit-card/amex-curved-64px.png","alt":"Visa","height":"37","max-width":"60"}})],1):_vm._e(),(
            _vm.options.showCardLogos &&
              [13].includes(_vm.selectedPayment.paymentTypeId) &&
              !_vm.$vuetify.breakpoint.xs
          )?_c('div',{staticClass:"d-flex align-center justify-end",attrs:{"no-gutters":""}},[_c('v-img',{attrs:{"eager":"","src":"/credit-card/paypal-transparent-64px.png","alt":"Paypal","height":"37","max-width":"90"}})],1):_vm._e()]),_c('GatewayWriter',{staticClass:"mt-5",attrs:{"payment-type":_vm.selectedPayment,"order-id":_vm.orderId,"component-name":_vm.selectedPayment.gatewayClient,"mode":_vm.mode,"options":_vm.mergedOptions,"isProfileView":_vm.isProfileView}})],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }